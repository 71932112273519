import React, { Suspense } from "react";
import { BrowserRouter as HashRouter, Switch, Route } from "react-router-dom";
import "./assets/sass/main.scss";

//pages

const LangBar = React.lazy(() => import("./Components/LangBar"));
const Navbar = React.lazy(() => import("./Components/Navbar"));
const Home = React.lazy(() => import("./Pages/Home"));
const About = React.lazy(() => import("./Pages/About"));
const Partners = React.lazy(() => import("./Pages/Partners"));
const Services = React.lazy(() => import("./Pages/Services"));
const Activity = React.lazy(() =>
  import("./Pages/Services/ServicesDetail/Activity"),
);
const Technological = React.lazy(() =>
  import("./Pages/Services/ServicesDetail/Technological"),
);
const Consultancy = React.lazy(() =>
  import("./Pages/Services/ServicesDetail/Consultancy"),
);
const GalleryPage = React.lazy(() => import("./Pages/GalleryPage"));
const Contact = React.lazy(() => import("./Pages/Contact"));

function App() {
  return (
    <div className="App">
      <HashRouter>
        <div>
          <Suspense
            fallback={
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          >
            <LangBar />
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/partners" component={Partners} />
              <Route path="/services" component={Services} />
              <Route path="/activity" component={Activity} />
              <Route path="/technological" component={Technological} />
              <Route path="/consultancy" component={Consultancy} />
              <Route path="/gallerypage" component={GalleryPage} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </Suspense>
        </div>
      </HashRouter>
    </div>
  );
}

export default App;
